var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"h-100 w-100",attrs:{"id":"map-container"}},[(_vm.loading && _vm.width > 1000 && _vm.mapHeight > 100)?_c('div',{staticClass:"search-as-i-move-the-map-control button-on-map checkbox bg-light position-absolute"},[_c('label',{staticClass:"m-0"},[_c('b-spinner',{attrs:{"small":"","variant":"primary","id":"mapSpinner"}}),_vm._v(" Loading ")],1)]):_vm._e(),(
      !_vm.hideSettings &&
        !_vm.boundsOutOfDate &&
        !_vm.loading &&
        _vm.width > 1000 &&
        _vm.mapHeight > 100
    )?_c('div',{staticClass:"search-as-i-move-the-map-control button-on-map checkbox bg-light position-absolute"},[_c('label',{staticClass:"m-0"},[_c('toggle-button',{staticClass:"mr-1",attrs:{"value":_vm.autoSearch,"color":_vm.colour,"sync":true,"disabled":_vm.sidebarActive,"labels":{ checked: 'on', unchecked: 'off' }},model:{value:(_vm.autoSearch),callback:function ($$v) {_vm.autoSearch=$$v},expression:"autoSearch"}}),_vm._v(" Search as I move the map ")],1)]):_vm._e(),(
      !_vm.hideSettings &&
        !_vm.autoSearch &&
        _vm.boundsOutOfDate &&
        !_vm.loading &&
        _vm.width > 1000 &&
        _vm.mapHeight > 100
    )?_c('button',{staticClass:"button-on-map btn position-absolute btn-light",attrs:{"id":"search-here"},on:{"click":_vm._mapDidChange}},[_c('BIcon',{staticClass:"mr-1",attrs:{"icon":"arrow-clockwise"}}),_vm._v("Re-do search here ")],1):_vm._e(),(!_vm.hideSettings && !_vm.loading && _vm.mapHeight >= 200)?_c('BButton',{staticClass:"position-absolute px-2",class:_vm.width > 1000 ? 'save-bounds' : 'save-bounds-mobile',attrs:{"id":"save-bounds","variant":"light","title":"Save map bounds to browser"},on:{"click":function($event){return _vm.saveBounds()}}},[_c('img',{staticClass:"pb-1",attrs:{"src":require("../assets/save-icon.svg"),"width":"20"}}),(_vm.width > 1000)?_c('span',{staticClass:"pl-1"},[_vm._v("Save Map")]):_vm._e()]):_vm._e(),(!_vm.hideSettings && !_vm.loading && _vm.mapHeight >= 200)?_c('BButton',{staticClass:"position-absolute px-2",class:_vm.width > 1000 ? 'map-settings' : 'map-settings-mobile',attrs:{"id":"map-settings","variant":"light","title":"Map settings"},on:{"click":function($event){return _vm.showMapSettingsModal()}}},[_c('BIcon',{attrs:{"icon":"gear-fill"}}),(_vm.width > 1000)?_c('span'):_vm._e()],1):_vm._e(),(
      !_vm.hideSettings &&
        !_vm.autoSearch &&
        _vm.boundsOutOfDate &&
        !_vm.loading &&
        _vm.width < 1000 &&
        _vm.mapHeight > 100
    )?_c('BButton',{staticClass:"btn position-absolute btn-light px-2",attrs:{"id":"refresh-search","title":"Save map bounds to browser"},on:{"click":_vm.mobileBoundRefresh}},[_c('BIcon',{staticClass:"text-dark",attrs:{"icon":"arrow-clockwise"}})],1):_vm._e(),_c('div',{staticClass:"h-100 w-100",attrs:{"id":`map${_vm.isSidebarMap ? '-sidebar' : ''}`}}),_c('MapSettingsModal',{attrs:{"width":_vm.width}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }