import { GAEvent } from '@/services/ga/events/GAEvent';
import BoundingBox from '@/services/api/models/BoundingBox';

export class EventSaveMapBounds extends GAEvent {
  public eventName = 'save-map-bounds';
  public bounds?: BoundingBox;
  public zoom?: number;

  constructor(bounds?: BoundingBox, zoom?: number) {
    super();
    this.bounds = bounds;
    this.zoom = zoom;
  }
}
