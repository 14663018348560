
import { Constants } from '@/utils/Constants';
import { setObjectItem } from '@/utils/LocalStorageUtils';
import { getMapSettings } from '@/utils/MapUtils';
import { isWaterTank, returnOnEntityAccess } from '@/utils/WaterUtils';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';

@Component
export default class MapSettingsModal extends mixins(AppName) {
  @Prop() width!: number;
  public mapTagValues: { [key: string]: { value: string; label: string }[] } = {
    milk: [
      { value: 'age', label: 'Milk Age' },
      { value: 'score', label: 'Milk Score' }
    ],
    fuel: [
      { value: 'remaining', label: 'Days Remaining' },
      { value: 'percentFull', label: 'Percent Full' }
    ],
    water: [
      { value: 'currentUsage', label: 'Current Usage' },
      { value: 'usageToday', label: 'Usage Today' },
      { value: 'usageYesterday', label: 'Usage Yesterday' }
    ],
    feed: [
      { value: 'remaining', label: 'Days Remaining' },
      { value: 'percentFull', label: 'Percent Full' }
    ]
  };
  public selectedMapTagValue: null | string = null;
  public waterTanksTagValues = [
    { value: 'percent', label: 'Percentage' },
    { value: 'volume', label: 'Volume' },
    { value: 'ullage', label: 'Ullage' }
  ];

  // Since this was created with a one product, one entity type model,
  // this handles if is a different entity type (water: water tanks)
  // Entity access is only defined on api return so on render it handles
  // default entity (water meters)
  public onOpen() {
    if (isWaterTank()) {
      this.mapTagValues.water = this.waterTanksTagValues;
      this.selectedMapTagValue = this.mapTagValues[
        this.getAppName(false)
      ][0].value;

      const mapSettings = getMapSettings();
      if (mapSettings) {
        this.selectedMapTagValue = mapSettings.mapTag;
      }
    }
  }

  mounted() {
    if (this.selectedMapTagValue === null) {
      this.selectedMapTagValue = this.mapTagValues[
        this.getAppName(false)
      ][0].value;
    }
  }

  created() {
    const mapSettings = getMapSettings();
    if (mapSettings) {
      this.selectedMapTagValue = mapSettings.mapTag;
    }
  }

  public saveSettings() {
    let suffix = '';
    if (this.getAppName(false) == Constants.PRODUCT_TYPE_WATER) {
      suffix = returnOnEntityAccess('-waterTank', '-waterMeter');
    }
    const mapSettings: any = { mapTag: this.selectedMapTagValue };
    setObjectItem(`${this.getAppName(false)}MapSettings${suffix}`, mapSettings);
    this.$root.$emit('mapSettingsUpdated');
    this.closeMapModal();
  }

  public closeMapModal() {
    this.$root.$emit('bv::hide::modal', 'mapSettingsModal', '#btnHide');
  }
}
