import { isApp, getAppColour, getAppName } from './AppName';
import { Constants, getBootstrapColourHex } from './Constants';
import { getObjectItem } from './LocalStorageUtils';
import { returnOnEntityAccess } from './WaterUtils';

export function getColour(isSelected: boolean, colour?: string) {
  // if isSelected arg isn't passed we'll set to false
  let color = getBootstrapColourHex('primary');
  if (
    isApp(Constants.PRODUCT_TYPE_MILK) &&
    colour &&
    getBootstrapColourHex(colour)
  ) {
    color = getBootstrapColourHex(colour);
  } else if (!isApp(Constants.PRODUCT_TYPE_MILK) && isSelected) {
    color = getAppColour(false);
  }
  return color.replace('#', '');
}

export const getMapSettings = () => {
  let suffix = '';
  if (getAppName(false) == Constants.PRODUCT_TYPE_WATER) {
    suffix = returnOnEntityAccess('-waterTank', '-waterMeter');
  }
  return getObjectItem(`${getAppName(false)}MapSettings${suffix}`);
};

export const getSelectedMarkerOutline = (colourHex: string) => {
  return `<path id="highlight" d="M56,3V37a3,3,0,0,1-3,3H39L28,51,17,40H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0H53a3,3,0,0,1,3,3" transform="translate(1744 160)" fill="#${colourHex}" opacity="0.2"/>`;
};

export const getMarker = (colourHex: string) => {
  return `<path id="icon" stroke="#fff" stroke-width="3px" stroke-opacity="0.4" d="M48,10V30a2,2,0,0,1-2,2H36l-8,8-8-8H10a2,2,0,0,1-2-2V10a2,2,0,0,1,2-2H46a2,2,0,0,1,2,2" transform="translate(1744 160)" fill="#${colourHex}"/>`;
};

export const getIcon = (colour: string) => {
  switch (colour) {
    case 'red':
      return `<g id="icon-red" transform="translate(1762 170)"><rect id="bg" width="20" height="20" fill="#fff" opacity="0"/><path id="icon-2" data-name="icon" d="M18.725,14.993l-7-12a2,2,0,0,0-3.456,0l-7,12A2,2,0,0,0,3,18H17a2,2,0,0,0,1.728-3.007M13,10v1a2,2,0,0,1-1.18,1.82L13,14v2H11V15l-1-1H9v2H7V9A1,1,0,0,1,8,8h4a1,1,0,0,1,1,1ZM9,10h2v2H9Z" fill="#fff"/></g>`;
    case 'orange':
      return `<g id="icon-orange" transform="translate(1762 170)"><rect id="bg" width="20" height="20" fill="#fff" opacity="0"/><path id="icon-2" data-name="icon" d="M10,10h1v4H9V10Zm7,8H3a2,2,0,0,1-1.728-3.007l7-12a2,2,0,0,1,3.456,0l7,12A2,2,0,0,1,17,18m-4-8a2,2,0,0,0-2-2H9a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2h2a2,2,0,0,0,2-2V10Z" fill="#fff"/></g>`;
    case 'yellow':
      return `<g id="icon-yellow" transform="translate(1762 170)"><rect id="bg" width="20" height="20" fill="#fff" opacity="0"/><path id="icon-2" data-name="icon" d="M18.725,14.993l-7-12a2,2,0,0,0-3.456,0l-7,12A2,2,0,0,0,3,18H17a2,2,0,0,0,1.728-3.007M13,11l-2,2v3H9V13L7,11V8H9v2l1,1,1-1V8h2Z" fill="#fff"/></g>`;
    case 'green':
      return `<g id="icon-green" transform="translate(1762 170)"><rect id="bg" width="20" height="20" fill="#fff" opacity="0"/><path id="icon-2" data-name="icon" d="M18.725,14.993l-7-12a2,2,0,0,0-3.456,0l-7,12A2,2,0,0,0,3,18H17a2,2,0,0,0,1.728-3.007M13,10H9v4h2V12h2v3a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V9A1,1,0,0,1,8,8h5Z" fill="#fff"/></g>`;
    default:
      return `<g id="icon-default" transform="translate(1762 170)"><rect id="Rectangle_6888" data-name="Rectangle 6888" width="6" height="2" transform="translate(7 10)" fill="#fff"/></g>`;
  }
};

export function getMarkerSvgString(selected: boolean, colour: string) {
  let svgString = '';
  const hex = getColour(selected, colour);
  if (selected) {
    svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="56" height="51" viewBox="0 0 56 51">
    <g id="fullpin-active" transform="translate(-1744 -160)">
    ${getSelectedMarkerOutline(hex)}${getMarker(hex)}${getIcon(colour)}
    </g>
  </svg>
  `;
  } else {
    svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="32" viewBox="0 0 40 32">
    <g id="fullpin-default" transform="translate(-1752 -168)">
    ${getMarker(hex)}${getIcon(colour)}
    </g>
  </svg>`;
  }
  return svgString;
}
